<template>
  <div class="hold-transition">
    <loading v-if="cargando" />
    <div class="wrapper">
      <div class="content-wrapper">
        <section class="content-header">
          <div class="container-fluid">
            <div class="row">
              <div class="col-sm-6">
                <h5 class="mb-0">Preliquidaciones</h5>
              </div>
              <div class="col-sm-6">
                <ol class="breadcrumb float-sm-right">
                  <li class="breadcrumb-item">
                    <router-link to="/">Home</router-link>
                  </li>
                  <li class="breadcrumb-item active">Tep</li>
                  <li class="breadcrumb-item active">Nacionales</li>
                  <li class="breadcrumb-item active">Liquidaciones</li>
                  <li class="breadcrumb-item active">Preliquidaciones</li>
                </ol>
              </div>
            </div>
          </div>
        </section>
        <section class="content">
          <div class="container-fluid">
            <div class="card">
              <div class="card-header">
                <div class="row">
                  <div class="col-6">
                    <h5>Filtros</h5>
                  </div>
                  <div class="col-6">
                    <div class="btn-group float-right">
                      <button
                        type="button"
                        class="btn bg-primary btn-sm"
                        @click="getIndex()"
                      >
                        <i class="fas fa-search"></i>
                      </button>
                      <button
                        type="button"
                        class="btn btn-success"
                        data-toggle="modal"
                        data-target="#modal-form-tep-preliquidacion-export"
                        v-if="
                          $store.getters.can(
                            'tep.preliquidacionesNacionales.export'
                          )
                        "
                      >
                        <i class="far fa-file-excel"></i>
                      </button>
                      <button
                        type="button"
                        class="btn btn-danger btn-sm"
                        @click="limpiar()"
                      >
                        <i class="fas fa-broom"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-body">
                <div class="row">
                  <div class="form-group col-md-2">
                    <label># Viaje</label>
                    <input
                      type="text"
                      class="form-control form-control-sm"
                      v-model="filtros.id"
                      @keypress.enter="getIndex()"
                    />
                  </div>
                  <div class="form-group col-md-4">
                    <label>Transportadora</label>
                    <v-select
                      :class="$store.getters.getDarkMode ? 'dark-vselect' : ''"
                      v-model="transportadora"
                      placeholder="Razon social"
                      label="razon_social"
                      :options="listasForms.transportadoras"
                      @input="seleccionarTransportadora()"
                    ></v-select>
                  </div>
                  <div class="form-group col-md-4">
                    <label>Ruta</label>
                    <v-select
                      :class="$store.getters.getDarkMode ? 'dark-vselect' : ''"
                      v-model="ruta"
                      placeholder="Ruta"
                      label="nombre"
                      :options="listasForms.rutas"
                      @input="seleccionarRuta()"
                    ></v-select>
                  </div>
                  <div class="form-group col-md-2">
                    <label for="estado">Estado</label>
                    <select
                      id="estado"
                      v-model="filtros.estado"
                      class="form-control form-control-sm"
                    >
                      <option value="">Seleccione...</option>
                      <option
                        v-for="estado in listasForms.estados"
                        :key="estado.numeracion"
                        :value="estado.numeracion"
                      >
                        {{ estado.descripcion }}
                      </option>
                      <option value="5"> No liquidado</option>
                    </select>
                  </div>
                  <div class="form-group col-md-2">
                    <label>Tipo de Vehículo</label>
                    <v-select
                      :class="$store.getters.getDarkMode ? 'dark-vselect' : ''"
                      v-model="tipo_vehiculo"
                      placeholder="Tipo Vehículo"
                      label="nombre"
                      :options="listasForms.tipos_vehiculos"
                      @input="seleccionarTipoVehiculo()"
                    ></v-select>
                  </div>
                  <div class="form-group col-md-2">
                    <label>Vehículo</label>
                    <v-select
                      :class="$store.getters.getDarkMode ? 'dark-vselect' : ''"
                      v-model="vehiculo"
                      placeholder="Placa"
                      label="placa"
                      :options="listasForms.vehiculos"
                      @input="seleccionarVehiculo()"
                    ></v-select>
                  </div>
                  <div class="form-group col-md-4">
                    <label>Conductor</label>
                    <v-select
                      :class="$store.getters.getDarkMode ? 'dark-vselect' : ''"
                      v-model="conductor"
                      placeholder="Conductror"
                      label="nConductor"
                      :options="listasForms.conductores"
                      @input="seleccionarConductor()"
                    ></v-select>
                  </div>
                  <div class="col-md-2">
                    <label>Desde</label>
                    <input
                      type="date"
                      class="form-control form-control-sm"
                      v-model="filtros.fecha_ini"
                      @input="validaFechasFiltro()"
                    />
                  </div>
                  <div class="col-md-2">
                    <label>Hasta</label>
                    <input
                      type="date"
                      class="form-control form-control-sm"
                      v-model="filtros.fecha_fin"
                      @input="validaFechasFiltro()"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section class="content">
          <div class="container-fluid">
            <div class="card">
              <div class="card-header border-0">
                <div class="row">
                  <div class="col-6">
                    <h5>Preliquidaciones</h5>
                  </div>
                </div>
              </div>
              <div class="card-body table-responsive p-0">
                <table
                  class="
                    table table-bordered table-striped table-hover table-sm
                  "
                  style="font-size: 12px"
                >
                  <thead class="thead-dark">
                    <tr class="text-center">
                      <th>Viaje</th>
                      <th>Estado</th>
                      <th>Transportadora</th>
                      <th>Ruta</th>
                      <th>Tipo Vehículo</th>
                      <th>Vehículo</th>
                      <th>Conductor</th>
                      <th>Fecha Inicial</th>
                      <th>Fecha Final</th>
                      <th>Horas Trabajadas</th>
                      <th>Km Recorridos</th>
                      <th>Acciones</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="preliquidacion in preliquidaciones.data"
                      :key="preliquidacion.id"
                    >
                      <td class="text-center">
                        {{ preliquidacion.id }}
                      </td>
                      <td class="text-center">
                        <span
                          class="badge"
                          v-if="preliquidacion.tep_preliquidacion_nacional"
                          :class="
                            !preliquidacion.tep_preliquidacion_nacional
                              ? 'bg-danger'
                              : preliquidacion.tep_preliquidacion_nacional
                                  .estado == 1
                              ? 'bg-info'
                              : preliquidacion.tep_preliquidacion_nacional
                                  .estado == 2
                              ? 'bg-navy'
                              : preliquidacion.tep_preliquidacion_nacional
                                  .estado == 3
                              ? 'bg-success'
                              : 'badge-gray'
                          "
                        >
                          {{
                            preliquidacion.tep_preliquidacion_nacional
                              ? preliquidacion.tep_preliquidacion_nacional
                                  .nEstado
                              : "No liquidado"
                          }}
                        </span>
                        <span v-else class="badge bg-gray"> No liquidado </span>
                      </td>
                      <td>
                        {{
                          preliquidacion.transportadora
                            ? preliquidacion.transportadora.razon_social
                            : ""
                        }}
                      </td>
                      <td :class="!preliquidacion.ruta ? 'text-center' : ''">
                        <div v-if="preliquidacion.ruta">
                          {{ preliquidacion.ruta.nombre }}
                        </div>
                        <div v-else>
                          <span class="badge bg-gray"
                            >Pago por kilometro recorrido
                          </span>
                        </div>
                      </td>
                      <td>
                        {{
                          preliquidacion.tipo_vehiculo
                            ? preliquidacion.tipo_vehiculo.nombre
                            : ""
                        }}
                      </td>
                      <td>
                        {{
                          preliquidacion.vehiculo
                            ? preliquidacion.vehiculo.placa
                            : ""
                        }}
                      </td>
                      <td>
                        {{
                          preliquidacion.conductor
                            ? `${preliquidacion.conductor.nombres} ${preliquidacion.conductor.apellidos}`
                            : ""
                        }}
                      </td>
                      <td class="text-center">
                        {{ preliquidacion.fecha_ini }}
                      </td>
                      <td class="text-center">
                        {{ preliquidacion.fecha_fin }}
                      </td>
                      <td class="text-center">
                        <div v-if="preliquidacion.tep_preliquidacion_nacional">
                          <div
                            v-if="
                              preliquidacion.tep_preliquidacion_nacional
                                .conc_horas_trabajadas
                            "
                          >
                            {{
                              preliquidacion.tep_preliquidacion_nacional
                                .conc_horas_trabajadas
                            }}
                          </div>
                          <div v-else>
                            {{
                              preliquidacion.tep_preliquidacion_nacional
                                .horas_trabajadas
                            }}
                          </div>
                        </div>
                        <div v-else>
                          <span class="badge bg-gray">
                            Sin preliquidación creada
                          </span>
                        </div>
                      </td>
                      <td class="text-center">
                        <div v-if="preliquidacion.tep_preliquidacion_nacional">
                          <div
                            v-if="
                              preliquidacion.tep_preliquidacion_nacional
                                .conc_km_recorridos
                            "
                          >
                            {{
                              preliquidacion.tep_preliquidacion_nacional
                                .conc_km_recorridos
                            }}
                          </div>
                          <div v-else>
                            {{
                              preliquidacion.tep_preliquidacion_nacional
                                .km_recorridos
                            }}
                          </div>
                        </div>
                        <div v-else>
                          <span class="badge bg-gray">
                            Sin preliquidación creada
                          </span>
                        </div>
                      </td>
                      <td class="text-right">
                        <div class="btn-group float-right">
                          <button
                            type="button"
                            class="btn bg-navy btn-sm"
                            data-toggle="modal"
                            data-target="#Modal_preliquidacion"
                            @click="getModalData(preliquidacion)"
                          >
                            <i class="fas fa-edit"></i>
                          </button>
                          <button
                            type="button"
                            class="btn btn-sm bg-danger"
                            @click="verPDF(preliquidacion.id)"
                          >
                            <i class="fas fa-file-pdf"></i>
                          </button>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="card-footer">
                <div class="float-left" v-if="preliquidaciones.total">
                  <p>
                    Mostrando del <b>{{ preliquidaciones.from }}</b> al
                    <b>{{ preliquidaciones.to }}</b> de un total:
                    <b>{{ preliquidaciones.total }}</b> Registros
                  </p>
                </div>
                <div class="float-left" v-else>
                  <p>
                    <span class="badge badge-danger">
                      No hay registros para mostrar
                    </span>
                  </p>
                </div>
                <pagination
                  :data="preliquidaciones"
                  @pagination-change-page="getIndex"
                  :limit="5"
                  class="float-right"
                ></pagination>
              </div>
            </div>
          </div>
        </section>
        <!-- Modal de detalle de la preliquidación -->
        <div class="modal fade" id="Modal_preliquidacion">
          <div class="modal-dialog modal-xl" v-if="modalData">
            <div class="modal-content">
              <div class="modal-header bg-frontera-top-left pt-2 pb-2">
                <h4 class="modal-title text-white">{{ modalTitle }}</h4>
                <button
                  type="button"
                  class="close text-white"
                  data-dismiss="modal"
                  aria-label="Close"
                  id="close-modal"
                  ref="closeModal"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body p-0 mt-1">
                <ul class="nav nav-tabs" id="tab_preliquidacion" role="tablist">
                  <li class="nav-item">
                    <a class="nav-link active" data-toggle="tab" href="#Detalle"
                      >Detalle</a
                    >
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" data-toggle="tab" href="#Preliquidacion"
                      >Preliquidación</a
                    >
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" data-toggle="tab" href="#Odometro"
                      >Odómetro</a
                    >
                  </li>
                  <li class="nav-item">
                    <a
                      class="nav-link"
                      data-toggle="tab"
                      href="#Trazabilidad"
                      @click="getTraza(), showGeocercas()"
                      >Trazabilidad</a
                    >
                  </li>
                  <li
                    class="nav-item"
                    v-if="modalData.tep_preliquidacion_nacional"
                  >
                    <a class="nav-link" data-toggle="tab" href="#Conciliacion"
                      >Conciliación</a
                    >
                  </li>
                  <li
                    class="nav-item"
                    v-if="
                      ($store.getters.can(
                        'tep.preliquidacionesNacionales.firmaFrontera'
                      ) ||
                        $store.getters.can(
                          'tep.preliquidacionesNacionales.firmaTransportadora'
                        )) &&
                        modalData.tep_preliquidacion_nacional &&
                        modalData.tep_preliquidacion_nacional.costo_total
                    "
                  >
                    <a class="nav-link" data-toggle="tab" href="#Firmas"
                      >Firmas</a
                    >
                  </li>
                </ul>
                <div class="tab-content" id="tab_preliquidacionContent">
                  <!-- Pestaña Detalle -->
                  <div class="tab-pane fade active show" id="Detalle">
                    <div class="card-body">
                      <div class="row">
                        <!-- Viaje -->
                        <div class="col-md-12">
                          <div
                            class="position-relative p-3 m-2 bg-light"
                            style="font-size: 0.85em"
                          >
                            <div class="ribbon-wrapper ribbon-lg">
                              <div
                                class="ribbon"
                                :class="
                                  !modalData.tep_preliquidacion_nacional
                                    ? 'bg-danger'
                                    : modalData.tep_preliquidacion_nacional
                                        .estado == 1
                                    ? 'bg-info'
                                    : modalData.tep_preliquidacion_nacional
                                        .estado == 2
                                    ? 'bg-navy'
                                    : modalData.tep_preliquidacion_nacional
                                        .estado == 3
                                    ? 'bg-success'
                                    : 'badge-gray'
                                "
                              >
                                <small>
                                  {{
                                    modalData.tep_preliquidacion_nacional
                                      ? modalData.tep_preliquidacion_nacional
                                          .nEstado
                                      : "No liquidado"
                                  }}
                                </small>
                              </div>
                            </div>
                            <div class="row">
                              <!-- Detalle -->
                              <div
                                class=" col-md-6 d-flex align-items-stretch flex-column"
                              >
                                <div class="card bg-white d-flex flex-fill">
                                  <div class="card-header">
                                    <h5 class="card-title">Datos Servicio</h5>
                                  </div>
                                  <div class="card-body">
                                    <div class="row">
                                      <p class="col-md-6 mb-1">
                                        <b>Numero Servicio:</b>
                                      </p>
                                      <p class="col-md-6 mb-1">
                                        {{ modalData.id }}
                                      </p>
                                    </div>
                                    <div class="row">
                                      <p class="col-md-6 mb-1">
                                        <b>Ruta:</b>
                                      </p>
                                      <p class="col-md-6 mb-1">
                                        {{
                                          modalData.tep_ruta_id
                                            ? modalData.ruta.nombre
                                            : null
                                        }}
                                      </p>
                                    </div>
                                    <div class="row">
                                      <p
                                        class="col-md-6 mb-1"
                                        v-if="modalData.user"
                                      >
                                        <b>Usuario Responsable:</b>
                                      </p>
                                      <p
                                        class="col-md-6 mb-1"
                                        v-if="modalData.user"
                                      >
                                        {{ modalData.user.name }}
                                      </p>
                                    </div>
                                    <div class="row">
                                      <p class="col-md-6 mb-1">
                                        <b>Dirección origen:</b>
                                      </p>
                                      <p class="col-md-6 mb-1">
                                        {{ modalData.direccion_origen }}
                                      </p>
                                    </div>
                                    <div class="row">
                                      <p class="col-md-6 mb-1">
                                        <b>Dirección destino:</b>
                                      </p>
                                      <p class="col-md-6 mb-1">
                                        {{ modalData.direccion_destino }}
                                      </p>
                                    </div>
                                    <div class="row">
                                      <p class="col-md-6 mb-1">
                                        <b>Transportadora:</b>
                                      </p>
                                      <p class="col-md-6 mb-1">
                                        {{
                                          modalData.transportadora
                                            ? modalData.transportadora
                                                .razon_social
                                            : ""
                                        }}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div
                                class=" col-md-6 d-flex align-items-stretch flex-column"
                              >
                                <div
                                  class="card bg-white d-flex flex-fill"
                                  v-if="modalData.tep_fechas_viajes"
                                >
                                  <div class="card-header">
                                    <h5 class="card-title">Fechas</h5>
                                  </div>
                                  <div class="card-body">
                                    <div class="row">
                                      <p class="col-md-6 mb-1">
                                        <b>Fecha inicio:</b>
                                      </p>
                                      <p class="col-md-6 mb-1">
                                        {{ modalData.fecha_ini }}
                                      </p>
                                    </div>
                                    <div class="row">
                                      <p class="col-md-6 mb-1">
                                        <b>Fecha fin:</b>
                                      </p>
                                      <p class="col-md-6 mb-1">
                                        {{ modalData.fecha_fin }}
                                      </p>
                                    </div>
                                    <div class="row">
                                      <p class="col-md-6 mb-1">
                                        <b>Fecha llegada conductor:</b>
                                      </p>
                                      <p class="col-md-6 mb-1">
                                        {{
                                          modalData.tep_fechas_viajes.length > 0
                                            ? modalData.tep_fechas_viajes[0]
                                                .fecha_llegada_app_conductor !==
                                              null
                                              ? modalData.tep_fechas_viajes[0]
                                                  .fecha_llegada_app_conductor
                                              : "Sin asignar"
                                            : "Sin asignar"
                                        }}
                                      </p>
                                    </div>
                                    <div class="row">
                                      <p class="col-md-6 mb-1">
                                        <b>Fecha inicio usuario:</b>
                                      </p>
                                      <p class="col-md-6 mb-1">
                                        {{
                                          modalData.tep_fechas_viajes.length > 0
                                            ? modalData.tep_fechas_viajes[0]
                                                .fecha_ini_app_user !== null
                                              ? modalData.tep_fechas_viajes[0]
                                                  .fecha_ini_app_user
                                              : "Sin Asignar"
                                            : "Sin asignar"
                                        }}
                                      </p>
                                    </div>
                                    <div class="row">
                                      <p class="col-md-6 mb-1">
                                        <b>Fecha inicio conductor:</b>
                                      </p>
                                      <p class="col-md-6 mb-1">
                                        {{
                                          modalData.tep_fechas_viajes.length > 0
                                            ? modalData.tep_fechas_viajes[0]
                                                .fecha_ini_app_conductor !==
                                              null
                                              ? modalData.tep_fechas_viajes[0]
                                                  .fecha_ini_app_conductor
                                              : "Sin Asignar"
                                            : "Sin asignar"
                                        }}
                                      </p>
                                    </div>
                                    <div class="row">
                                      <p class="col-md-6 mb-1">
                                        <b>Fecha fin usuario:</b>
                                      </p>
                                      <p class="col-md-6 mb-1">
                                        {{
                                          modalData.tep_fechas_viajes.length > 0
                                            ? modalData.tep_fechas_viajes[0]
                                                .fecha_fin_app_user !== null
                                              ? modalData.tep_fechas_viajes[0]
                                                  .fecha_fin_app_user
                                              : "Sin Asignar"
                                            : "Sin asignar"
                                        }}
                                      </p>
                                    </div>
                                    <div class="row">
                                      <p class="col-md-6 mb-1">
                                        <b>Fecha fin conductor:</b>
                                      </p>
                                      <p class="col-md-6 mb-1">
                                        {{
                                          modalData.tep_fechas_viajes.length > 0
                                            ? modalData.tep_fechas_viajes[0]
                                                .fecha_fin_app_conductor !==
                                              null
                                              ? modalData.tep_fechas_viajes[0]
                                                  .fecha_fin_app_conductor
                                              : "Sin Asignar"
                                            : "Sin asignar"
                                        }}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div class="col-md-12">
                                <div class="card">
                                  <div class="card-header text-center">
                                    <h5 class="card-title">Pasajeros</h5>
                                  </div>
                                  <div class="card-body">
                                    <div class="row">
                                      <div class="col-md-12">
                                        <table
                                          class="table table-sm table-striped table-hover table-bordered"
                                          v-if="modalData.itinerario"
                                        >
                                          <thead>
                                            <tr>
                                              <th>Nombre</th>
                                              <th>Correo</th>
                                              <th>Telefono</th>
                                              <th>Empresa</th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            <tr
                                              v-for="pasajero in modalData
                                                .itinerario.pasajeros"
                                              :key="pasajero.id"
                                            >
                                              <td>{{ pasajero.nombre }}</td>
                                              <td>{{ pasajero.email }}</td>
                                              <td>{{ pasajero.telefono }}</td>
                                              <td>{{ pasajero.empresa }}</td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <!-- Vehículo -->
                              <div
                                class="
                              col-md-6
                              d-flex
                              align-items-stretch
                              flex-column
                            "
                              >
                                <div class="card bg-white d-flex flex-fill">
                                  <div class="card-header">
                                    <h5 class="card-title">
                                      Vehículo
                                    </h5>
                                  </div>
                                  <div class="card-body">
                                    <div class="row" v-if="modalData.vehiculo">
                                      <div
                                        :class="
                                          modalData.vehiculo.link_fotografia
                                            ? 'col-md-7'
                                            : 'col-md-12'
                                        "
                                      >
                                        <h2 class="lead">
                                          <b>{{ modalData.vehiculo.placa }}</b>
                                        </h2>
                                        <p
                                          class="text-muted text-sm"
                                          v-if="modalData.transportadora"
                                        >
                                          <b>Transportadora: </b>
                                          {{
                                            modalData.transportadora
                                              .razon_social
                                          }}
                                        </p>
                                        <ul class="ml-4 mb-0 fa-ul text-muted">
                                          <li class="small">
                                            <span class="fa-li"
                                              ><i class="fas fa-lg fa-car"></i
                                            ></span>
                                            Tipo Vehículo:
                                            {{
                                              modalData.vehiculo.tipo_vehiculo
                                                .nombre
                                            }}
                                          </li>
                                        </ul>
                                      </div>
                                      <div
                                        class="col-md-5 text-center"
                                        v-if="
                                          modalData.vehiculo.link_fotografia
                                        "
                                      >
                                        <img
                                          :src="
                                            uri_docs +
                                              modalData.vehiculo.link_fotografia
                                          "
                                          alt="Vehículo"
                                          class="img-bordered img-rounded img-fluid"
                                        />
                                      </div>
                                    </div>
                                    <div v-else>
                                      <div class="col-md-12 text-center">
                                        <h4 class="text-muted">Sin asignar</h4>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <!-- Conductor -->
                              <div
                                class="
                              col-md-6
                              d-flex
                              align-items-stretch
                              flex-column
                            "
                              >
                                <div class="card bg-white d-flex flex-fill">
                                  <div class="card-header">
                                    <h5 class="card-title">
                                      Conductor
                                    </h5>
                                  </div>
                                  <div class="card-body">
                                    <div class="row" v-if="modalData.conductor">
                                      <div
                                        :class="
                                          modalData.conductor.link_fotografia
                                            ? 'col-md-7'
                                            : 'col-md-12'
                                        "
                                      >
                                        <h2 class="lead">
                                          <b
                                            >{{ modalData.conductor.nombres }}
                                            {{ modalData.conductor.apellidos }}
                                          </b>
                                        </h2>
                                        <p
                                          v-if="modalData.encuesta"
                                          class="text-muted text-sm"
                                        >
                                          <b>Calificación: </b>
                                          {{ encu.calificacion }}
                                          <i class="fas fa-star"></i>
                                        </p>
                                        <p v-else class="text-muted text-sm">
                                          <b>Calificación: </b>
                                          Sin calificación
                                        </p>
                                        <p class="text-muted text-sm">
                                          <b>Documento: </b>
                                          {{
                                            modalData.conductor.numero_documento
                                          }}
                                        </p>
                                        <ul class="ml-4 mb-0 fa-ul text-muted">
                                          <li class="small">
                                            <span class="fa-li"
                                              ><i
                                                class="fas fa-lg fa-envelope"
                                              ></i
                                            ></span>
                                            E-mail:
                                            {{ modalData.conductor.email }}
                                          </li>
                                          <li class="small">
                                            <span class="fa-li"
                                              ><i
                                                class="fas fa-lg fa-mobile"
                                              ></i
                                            ></span>
                                            Celular Principal:
                                            {{
                                              modalData.conductor
                                                .celular_principal
                                            }}
                                          </li>
                                          <li class="small">
                                            <span class="fa-li"
                                              ><i class="fas fa-lg fa-phone"></i
                                            ></span>
                                            Celular Alterno:
                                            {{
                                              modalData.conductor
                                                .celular_alterno
                                            }}
                                          </li>
                                        </ul>
                                      </div>
                                      <div
                                        class="col-md-5 text-center"
                                        v-if="
                                          modalData.conductor.link_fotografia
                                        "
                                      >
                                        <img
                                          :src="
                                            uri_docs +
                                              modalData.conductor
                                                .link_fotografia
                                          "
                                          alt="Conductor"
                                          class="img-bordered img-rounded img-fluid"
                                        />
                                      </div>
                                    </div>
                                    <div v-else>
                                      <div class="col-md-12 text-center">
                                        <h4 class="text-muted">Sin asignar</h4>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <!-- Odómetro -->
                        <div class="col-md-12">
                          <div class="position-relative p-3 m-2 bg-light">
                            <div class="ribbon-wrapper ribbon-lg">
                              <div class="ribbon bg-primary">Diferencia</div>
                            </div>
                            <div class="row">
                              <div class="col-md-12">
                                <div class="row">
                                  <div
                                    class="col-sm-12 d-flex align-items-stretch flex-column"
                                  >
                                    <div class="card bg-white d-flex flex-fill">
                                      <div
                                        class="card-header text-muted border-bottom-0 text-center"
                                      >
                                        Kilometros Recorridos
                                      </div>
                                      <div class="card-body pt-0">
                                        <div class="row">
                                          <div class="col-md-12 text-center">
                                            <h3 class="lead">
                                              <b>{{
                                                (
                                                  modalData.odometro_fin -
                                                  modalData.odometro_ini
                                                ).toFixed(2)
                                              }}</b>
                                            </h3>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-12">
                          <div class="position-relative p-3 m-2 bg-light">
                            <div class="ribbon-wrapper ribbon-lg">
                              <div class="ribbon bg-info">Odómetro</div>
                            </div>
                            <div
                              class="row"
                              v-if="
                                modalData.odometro_ini ||
                                  modalData.foto_odometro_ini ||
                                  modalData.odometro_fin ||
                                  modalData.foto_odometro_fin
                              "
                            >
                              <div class="col-md-12">
                                <div class="row">
                                  <div
                                    class="
                                  col-md-6
                                  d-flex
                                  align-items-stretch
                                  flex-column
                                "
                                    v-if="modalData.odometro_ini"
                                  >
                                    <div class="card bg-white d-flex flex-fill">
                                      <div
                                        class="
                                      card-header
                                      text-muted
                                      border-bottom-0
                                    "
                                      >
                                        Odómetro Inicial
                                      </div>
                                      <div class="card-body">
                                        <div class="row">
                                          <div class="col-md-4">
                                            <h2 class="lead">
                                              <b>{{
                                                modalData.odometro_ini
                                              }}</b>
                                            </h2>
                                          </div>
                                          <div
                                            class="col-md-8 text-center"
                                            v-if="modalData.foto_odometro_ini"
                                          >
                                            <img
                                              @click="
                                                getImage(
                                                  modalData.foto_odometro_ini
                                                )
                                              "
                                              :src="
                                                uri_docs +
                                                  modalData.foto_odometro_ini
                                              "
                                              alt="Odómetro Inicial"
                                              class="
                                            img-bordered img-rounded img-fluid
                                          "
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    class="
                                  col-md-6
                                  d-flex
                                  align-items-stretch
                                  flex-column
                                "
                                    v-if="modalData.odometro_fin"
                                  >
                                    <div class="card bg-white d-flex flex-fill">
                                      <div
                                        class="
                                      card-header
                                      text-muted
                                      border-bottom-0
                                    "
                                      >
                                        Odómetro Final
                                      </div>
                                      <div class="card-body">
                                        <div class="row">
                                          <div class="col-md-4">
                                            <h2 class="lead">
                                              <b>{{
                                                modalData.odometro_fin
                                              }}</b>
                                            </h2>
                                          </div>
                                          <div
                                            class="col-md-8 text-center"
                                            v-if="modalData.foto_odometro_fin"
                                          >
                                            <img
                                              @click="
                                                getImage(
                                                  modalData.foto_odometro_fin
                                                )
                                              "
                                              :src="
                                                uri_docs +
                                                  modalData.foto_odometro_fin
                                              "
                                              alt="Odómetro Final"
                                              class="
                                            img-bordered img-rounded img-fluid
                                          "
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div v-else>
                              <div class="col-md-12 text-center p-5">
                                <h4 class="text-muted">Sin registrar</h4>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- Pestaña Preliquidacion -->
                  <div class="tab-pane fade" id="Preliquidacion">
                    <div class="card-body">
                      <div class="row mb-4">
                        <div class="form-group col-md-10">
                          <label for="">Contrato</label>
                          <v-select
                            :class="
                              $store.getters.getDarkMode ? 'dark-vselect' : ''
                            "
                            v-model="contrato"
                            placeholder="Descripcion"
                            label="no_contrato"
                            :options="listasForms.contratos"
                            @input="seleccionarContrato()"
                            :disabled="bloq == 1"
                          ></v-select>
                        </div>
                      </div>
                      <div class="row mb-4" v-if="formContrato.id != null">
                        <div class="form-group col-md-10">
                          <input
                            type="text"
                            v-model="filterTarifas"
                            class="form-control"
                            placeholder="Buscar Tarifa"
                          />
                        </div>

                        <div class="col-md-2 form-group" v-if="!modalData.ruta">
                          <label>Ida y Vuelta</label>
                          <span class="description-text text-secondary">
                            <input
                              type="checkbox"
                              class="form-control form-control-sm"
                              v-model="round_trip"
                              @change="getModalData(modalData)"
                            />
                          </span>
                        </div>
                      </div>
                      <div
                        class="row justify-content-md-center"
                        v-show="formContrato.id != null"
                      >
                        <div
                          class="col-md-4"
                          v-for="(data, index) in filteredRowsDetail"
                          :key="index"
                        >
                          <div class="card" :class="data.view.color">
                            <div class="card-header">
                              <h3 class="card-title">
                                {{ data.view.title }}
                              </h3>
                            </div>
                            <div
                              class="card-body"
                              :class="
                                modalData.tep_preliquidacion_nacional &&
                                modalData.tep_preliquidacion_nacional
                                  .tep_viaje_id == data.form.tep_viaje_id &&
                                modalData.tep_preliquidacion_nacional
                                  .tep_det_tarifa_nal_id ==
                                  data.form.tep_det_tarifa_nal_id
                                  ? 'bg-light'
                                  : ''
                              "
                            >
                              <div class="row">
                                <div
                                  class="form-group col-md-12"
                                  :class="
                                    modalData.tep_preliquidacion_nacional &&
                                    modalData.tep_preliquidacion_nacional
                                      .tep_viaje_id == data.form.tep_viaje_id &&
                                    modalData.tep_preliquidacion_nacional
                                      .tep_det_tarifa_nal_id ==
                                      data.form.tep_det_tarifa_nal_id
                                      ? 'text-dark'
                                      : 'text-muted'
                                  "
                                >
                                  <p>
                                    <b>
                                      {{
                                        data.view.num_tarifa
                                          ? `(${data.view.num_tarifa})`
                                          : ""
                                      }}
                                      {{ data.view.tarifa }}
                                    </b>
                                  </p>
                                  <ul class="ml-4 mb-0 fa-ul">
                                    <li class="mb-1">
                                      <span class="fa-li">
                                        <i class="fas fa-lg fa-dollar-sign"></i>
                                      </span>
                                      Tarifa Base: ${{ data.view.tarifa_base }}
                                    </li>
                                    <li class="mb-1">
                                      <span class="fa-li">
                                        <i class="fas fa-lg fa-calendar"></i>
                                      </span>
                                      Vigencia: {{ data.view.fecha_ini }} -
                                      {{ data.view.fecha_fin }}
                                    </li>
                                    <li class="mb-1">
                                      <span class="fa-li">
                                        <i class="fas fa-lg fa-route"></i>
                                      </span>
                                      Rango Km: {{ data.view.rango_km_ini }} -
                                      {{ data.view.rango_km_fin }}
                                    </li>
                                    <li
                                      class="mb-1"
                                      v-if="data.view.cant_horas_add > 0"
                                    >
                                      <span class="fa-li">
                                        <i
                                          class="fas fa-lg fa-business-time"
                                        ></i>
                                      </span>
                                      Horas Extra: ${{
                                        data.view.costo_horas_add
                                      }}
                                      ({{ data.view.cant_horas_add }})
                                    </li>
                                    <li
                                      class="mb-1"
                                      v-if="data.view.cant_km_add"
                                    >
                                      <span class="fa-li">
                                        <i class="fas fa-lg fa-road"></i>
                                      </span>
                                      Km Adicional: ${{
                                        data.view.costo_km_add
                                      }}
                                      ({{ data.view.cant_km_add }})
                                    </li>
                                    <li
                                      class="mb-1"
                                      v-if="data.view.cant_peajes_pago > 0"
                                    >
                                      <span class="fa-li">
                                        <i
                                          class="fas fa-lg fa-truck-moving"
                                        ></i>
                                      </span>
                                      Peajes para pago:
                                      {{ data.view.cant_peajes_pago }}
                                    </li>
                                  </ul>
                                </div>
                              </div>
                              <div class="row">
                                <div class="col-md-3">
                                  <input
                                    type="radio"
                                    name="preliquidacion"
                                    class="form-control form-control-sm"
                                    v-if="
                                      $store.getters.can(
                                        'tep.preliquidacionesNacionales.generarPreliquidacion'
                                      )
                                    "
                                    :checked="
                                      modalData.tep_preliquidacion_nacional &&
                                        modalData.tep_preliquidacion_nacional
                                          .tep_viaje_id ==
                                          data.form.tep_viaje_id &&
                                        modalData.tep_preliquidacion_nacional
                                          .tep_det_tarifa_nal_id ==
                                          data.form.tep_det_tarifa_nal_id
                                    "
                                    :disabled="
                                      modalData.tep_preliquidacion_nacional &&
                                        modalData.tep_preliquidacion_nacional
                                          .costo_total
                                    "
                                    @click="getPreliquidacionForm(data.form)"
                                  />
                                </div>
                                <div class="col-md-9 text-right">
                                  <h4>${{ data.view.valor_tarifa }}</h4>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row justify-content-md-center">
                        <div class="col-md-2">
                          <button
                            type="button"
                            class="btn btn-primary"
                            v-if="
                              $store.getters.can(
                                'tep.preliquidacionesNacionales.generarPreliquidacion'
                              ) && !$v.preLiqForm.$invalid
                            "
                            @click="save('ininerario')"
                          >
                            Guardar Preliquidación
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- Pestaña Odómetro -->
                  <div class="tab-pane fade" id="Odometro">
                    <div class="container">
                      <div class="row justify-content-md-center">
                        <div class="col-md-12">
                          <div class="position-relative p-3 m-2 bg-light">
                            <div class="row">
                              <div class="col-md-12">
                                <div class="row">
                                  <div
                                    class="
                                      col-sm-6
                                      d-flex
                                      align-items-stretch
                                      flex-column
                                    "
                                  >
                                    <div class="card bg-white d-flex flex-fill">
                                      <div
                                        class="
                                          card-header
                                          text-muted
                                          border-bottom-0
                                        "
                                      >
                                        Odómetro Inicial
                                      </div>
                                      <div class="card-body pt-0">
                                        <div class="row">
                                          <div class="col-md-6">
                                            <h3 class="lead">
                                              <b>{{
                                                modalData.odometro_ini
                                              }}</b>
                                            </h3>
                                          </div>
                                          <div class="col-md-8 text-center">
                                            <img
                                              @click="
                                                getImage(
                                                  modalData.foto_odometro_ini
                                                )
                                              "
                                              :src="
                                                uri_docs +
                                                  modalData.foto_odometro_ini
                                              "
                                              alt="Odómetro Inicial"
                                              class="
                                                img-bordered
                                                img-rounded
                                                img-fluid
                                              "
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div
                                    class="
                                      col-md-6
                                      d-flex
                                      align-items-stretch
                                      flex-column
                                    "
                                  >
                                    <div class="card bg-white d-flex flex-fill">
                                      <div
                                        class="
                                          card-header
                                          text-muted
                                          border-bottom-0
                                        "
                                      >
                                        Odómetro Final
                                      </div>
                                      <div class="card-body pt-0">
                                        <div class="row">
                                          <div class="col-md-5">
                                            <h3 class="lead">
                                              <b>{{
                                                modalData.odometro_fin
                                              }}</b>
                                            </h3>
                                          </div>
                                          <div class="col-md-8 text-center">
                                            <img
                                              @click="
                                                getImage(
                                                  modalData.foto_odometro_fin
                                                )
                                              "
                                              :src="
                                                uri_docs +
                                                  modalData.foto_odometro_fin
                                              "
                                              alt="Odómetro Final"
                                              class="
                                                img-bordered
                                                img-rounded
                                                img-fluid
                                              "
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- Pestaña Trazabilidad -->
                  <div
                    class="tab-pane fade"
                    id="Trazabilidad"
                    v-if="preliquidacion_id"
                  >
                    <!-- contenido del mapa -->
                    <div class="card-body" v-if="!mensaje_error">
                      <div class="row">
                        <div class="col-md-8">
                          <gmap-map
                            :center="center"
                            :zoom="zoom"
                            style="width: 100%; height: 650px"
                          >
                            <gmap-marker
                              :key="index"
                              v-for="(m, index) in markers"
                              :position="m.position"
                              :icon="m.icon"
                              :animation="2"
                              @click="toggleInfoWindow(m, index, m.info)"
                            ></gmap-marker>
                            <gmap-marker
                              :key="index"
                              v-for="(n, index) in markers_viaje"
                              :position="n.position"
                              :icon="n.icon"
                              :animation="3"
                              @click="toggleInfoWindow(n, index, n.info)"
                            ></gmap-marker>
                            <gmap-info-window
                              :options="infoOptions"
                              :position="infoWindowPos"
                              :opened="infoWinOpen"
                              @closeclick="infoWinOpen = false"
                            >
                              <div v-html="infoContent"></div>
                            </gmap-info-window>
                            <gmap-polygon
                              v-for="poligono in poligonos"
                              :options="{ fillColor: poligono.color }"
                              :key="poligono.id"
                              :paths="poligono.path"
                              :editable="false"
                              :draggable="false"
                              @click="
                                toggleInfoWindowPoli(
                                  poligono,
                                  poligono.id,
                                  poligono.info
                                )
                              "
                            />
                            <gmap-circle
                              v-for="circular in circunferencias"
                              :key="circular.id"
                              :center="circular.center"
                              :radius="circular.radius"
                              :info="circular.info"
                              @click="
                                toggleInfoWindowCir(
                                  circular,
                                  circular.id,
                                  circular.info
                                )
                              "
                            ></gmap-circle>
                          </gmap-map>
                        </div>
                        <div class="col-md-4">
                          <!-- Origen y destino -->
                          <table
                            class="table table-sm table-striped table-bordered"
                          >
                            <thead class="thead-dark">
                              <tr>
                                <th colspan="3" class="text-center">Viaje</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td class="text-center">
                                  <span class="badge bg-olive">Origen</span>
                                </td>
                                <td>
                                  {{ modalData.direccion_origen }}
                                </td>
                              </tr>
                              <tr>
                                <td class="text-center">
                                  <span class="badge bg-pink">Destino</span>
                                </td>
                                <td>
                                  {{ modalData.direccion_destino }}
                                </td>
                              </tr>

                              <tr>
                                <td class="text-center">
                                  <img
                                    src="/img/volante1.png"
                                    class="figure-img img-fluid rounded"
                                  />
                                </td>
                                <td>
                                  Inicio Conductor -
                                  {{ modalData.lat_long_ini_conductor }}
                                </td>
                              </tr>
                              <tr>
                                <td class="text-center">
                                  <img
                                    src="/img/volante2.png"
                                    class="figure-img img-fluid rounded"
                                  />
                                </td>
                                <td>
                                  Fin Conductor -
                                  {{ modalData.lat_long_fin_conductor }}
                                </td>
                              </tr>
                              <tr>
                                <td class="text-center">
                                  <img
                                    src="/img/user1.png"
                                    class="figure-img img-fluid rounded"
                                  />
                                </td>
                                <td>
                                  Inicio Usuario -
                                  {{ modalData.lat_long_ini_user }}
                                </td>
                              </tr>
                              <tr>
                                <td class="text-center">
                                  <img
                                    src="/img/user.png"
                                    class="figure-img img-fluid rounded"
                                  />
                                </td>
                                <td>
                                  Fin Usuario -
                                  {{ modalData.lat_long_fin_user }}
                                </td>
                              </tr>
                              <tr>
                                <td class="text-center">
                                  <img
                                    src="/img/icon_punto_g.png"
                                    class="figure-img img-fluid rounded"
                                  />
                                </td>
                                <td>
                                  Llegada - {{ modalData.lat_long_llegada }}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    <!-- Mensaje de no trazabilidad -->
                    <div class="card-body" v-else>
                      <div class="info-box bg-gradient-warning">
                        <span class="info-box-icon"
                          ><i class="fas fa-exclamation-triangle"></i
                        ></span>
                        <div class="info-box-content">
                          <span class="info-box-text"
                            >No hay trazabilidad de este vehículo</span
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- Pestaña Conciliación -->
                  <div
                    class="tab-pane fade"
                    id="Conciliacion"
                    v-if="modalData.tep_preliquidacion_nacional"
                  >
                    <!-- Detalle -->
                    <div class="card-body">
                      <div class="row">
                        <div class="col-md-12">
                          <div
                            class="position-relative p-3 m-2 bg-light"
                            style="font-size: 0.85em"
                          >
                            <div class="row">
                              <div class="col-sm-2">
                                <div class="description-block">
                                  <h5 class="description-header">
                                    {{ modalData.id }}
                                  </h5>
                                  <span class="description-text text-secondary"
                                    >SETT</span
                                  >
                                </div>
                              </div>
                              <div class="col-sm-2">
                                <div class="description-block">
                                  <h5 class="description-header">
                                    {{ modalData.fecha_ini }}
                                  </h5>
                                  <span class="description-text text-secondary"
                                    >Inicio SETT</span
                                  >
                                </div>
                              </div>
                              <div class="col-sm-2">
                                <div class="description-block">
                                  <h5 class="description-header">
                                    {{ modalData.fecha_fin }}
                                  </h5>
                                  <span class="description-text text-secondary"
                                    >Fin SETT</span
                                  >
                                </div>
                              </div>
                              <div class="col-sm-2">
                                <div class="description-block">
                                  <h5 class="description-header">
                                    ${{
                                      modalData.tep_preliquidacion_nacional
                                        .costo_tarifa
                                    }}
                                  </h5>
                                  <span class="description-text text-secondary"
                                    >Tarifa Base</span
                                  >
                                </div>
                              </div>
                              <div
                                class="col-sm-2"
                                v-if="
                                  modalData.tep_preliquidacion_nacional
                                    .det_tarifa_nacional.valor_horas_extra
                                "
                              >
                                <div class="description-block">
                                  <h5 class="description-header">
                                    ${{
                                      modalData.tep_preliquidacion_nacional
                                        .det_tarifa_nacional.valor_horas_extra
                                    }}
                                  </h5>
                                  <span class="description-text text-secondary"
                                    >Valor Hora Extra</span
                                  >
                                </div>
                              </div>
                              <div
                                class="col-sm-2"
                                v-if="
                                  modalData.tep_preliquidacion_nacional
                                    .det_tarifa_nacional.valor_km_adicional
                                "
                              >
                                <div class="description-block">
                                  <h5 class="description-header">
                                    ${{
                                      modalData.tep_preliquidacion_nacional
                                        .det_tarifa_nacional.valor_km_adicional
                                    }}
                                  </h5>
                                  <span class="description-text text-secondary"
                                    >Valor Km. Adicional</span
                                  >
                                </div>
                              </div>
                              <div
                                class="col-sm-2"
                                v-if="
                                  $store.getters.can(
                                    'tep.preliquidacionesNacionales.conciliarPreliquidacion'
                                  )
                                "
                              >
                                <div class="description-block">
                                  <h5 class="description-header">
                                    <div
                                      v-if="
                                        modalData.tep_preliquidacion_nacional
                                          .conc_km_recorridos
                                      "
                                    >
                                      {{
                                        modalData.tep_preliquidacion_nacional
                                          .conc_km_recorridos
                                      }}
                                    </div>
                                    <div v-else>
                                      {{
                                        modalData.tep_preliquidacion_nacional
                                          .km_recorridos
                                      }}
                                    </div>
                                  </h5>
                                  <span class="description-text text-secondary"
                                    >Km Recorridos</span
                                  >
                                </div>
                              </div>
                              <div
                                class="col-sm-2"
                                v-if="
                                  $store.getters.can(
                                    'tep.preliquidacionesNacionales.conciliarPreliquidacion'
                                  )
                                "
                              >
                                <div class="description-block">
                                  <h5 class="description-header">
                                    <div
                                      v-if="
                                        modalData.tep_preliquidacion_nacional
                                          .conc_horas_trabajadas
                                      "
                                    >
                                      {{
                                        modalData.tep_preliquidacion_nacional
                                          .conc_horas_trabajadas
                                      }}
                                    </div>
                                    <div v-else>
                                      {{
                                        modalData.tep_preliquidacion_nacional
                                          .horas_trabajadas
                                      }}
                                    </div>
                                  </h5>
                                  <span class="description-text text-secondary"
                                    >Horas Trabajadas</span
                                  >
                                </div>
                              </div>
                              <div
                                class="col-sm-2"
                                v-if="
                                  modalData.tep_preliquidacion_nacional &&
                                    modalData.tep_preliquidacion_nacional
                                      .det_tarifa_nacional &&
                                    modalData.tep_preliquidacion_nacional
                                      .det_tarifa_nacional.tarifa_nacional &&
                                    modalData.tep_preliquidacion_nacional
                                      .det_tarifa_nacional.tarifa_nacional
                                      .contrato
                                "
                              >
                                <div class="description-block">
                                  <h5 class="description-header">
                                    {{
                                      modalData.tep_preliquidacion_nacional
                                        .det_tarifa_nacional.tarifa_nacional
                                        .contrato.peajes
                                    }}
                                  </h5>
                                  <span class="description-text text-secondary"
                                    >Peajes Contrato</span
                                  >
                                </div>
                              </div>
                              <div
                                class="col-sm-2"
                                v-if="
                                  modalData.tep_preliquidacion_nacional &&
                                    modalData.tep_preliquidacion_nacional
                                      .det_tarifa_nacional &&
                                    modalData.tep_preliquidacion_nacional
                                      .det_tarifa_nacional.tarifa_nacional &&
                                    modalData.tep_preliquidacion_nacional
                                      .det_tarifa_nacional.tarifa_nacional
                                      .contrato
                                "
                              >
                                <div class="description-block">
                                  <h5 class="description-header">
                                    ${{
                                      modalData.tep_preliquidacion_nacional
                                        .det_tarifa_nacional.tarifa_nacional
                                        .contrato.pernocte
                                    }}
                                  </h5>
                                  <span class="description-text text-secondary"
                                    >Valor Pernocte</span
                                  >
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- Formulario -->
                    <div class="panel-body p-3">
                      <div class="row">
                        <div class="form-group col-md-3">
                          <label>Horas Extra</label
                          ><input
                            type="number"
                            class="form-control form-control-sm"
                            v-model="form.conc_cant_horas_add"
                            :class="
                              $v.form.conc_cant_horas_add.$invalid
                                ? 'is-invalid'
                                : 'is-valid'
                            "
                            :disabled="
                              modalData.tep_preliquidacion_nacional.estado ==
                                3 ||
                                !$store.getters.can(
                                  'tep.preliquidacionesNacionales.conciliarPreliquidacion'
                                )
                            "
                            @input="calcCostoHorasExtra()"
                          />
                          <span
                            >Valor Horas Extra:
                            <strong>${{ form.costo_horas_add }}</strong></span
                          >
                        </div>
                        <div class="form-group col-md-3">
                          <label>Kilómetros Extra</label
                          ><input
                            type="number"
                            class="form-control form-control-sm"
                            v-model="form.conc_cant_km_add"
                            :class="
                              $v.form.conc_cant_km_add.$invalid
                                ? 'is-invalid'
                                : 'is-valid'
                            "
                            :disabled="
                              modalData.tep_preliquidacion_nacional.estado ==
                                3 ||
                                !$store.getters.can(
                                  'tep.preliquidacionesNacionales.conciliarPreliquidacion'
                                )
                            "
                            @input="calcCostoKmAdicionales()"
                          />
                          <span
                            >Valor Km Extra:
                            <strong>${{ form.costo_km_add }}</strong></span
                          >
                        </div>
                        <div class="form-group col-md-3">
                          <label>Cantidad Peajes</label
                          ><input
                            type="number"
                            class="form-control form-control-sm"
                            v-model="form.conc_cant_peajes"
                            :class="
                              $v.form.conc_cant_peajes.$invalid
                                ? 'is-invalid'
                                : 'is-valid'
                            "
                            :disabled="
                              modalData.tep_preliquidacion_nacional.estado ==
                                3 ||
                                !$store.getters.can(
                                  'tep.preliquidacionesNacionales.conciliarPreliquidacion'
                                )
                            "
                            @input="calcPeajes()"
                          />
                        </div>
                        <div class="form-group col-md-3">
                          <label>Costo Peajes</label
                          ><input
                            type="number"
                            class="form-control form-control-sm"
                            v-model="form.costo_peajes"
                            :class="
                              $v.form.costo_peajes.$invalid
                                ? 'is-invalid'
                                : 'is-valid'
                            "
                            :disabled="
                              modalData.tep_preliquidacion_nacional.estado ==
                                3 ||
                                !$store.getters.can(
                                  'tep.preliquidacionesNacionales.conciliarPreliquidacion'
                                ) ||
                                !form.conc_cant_peajes ||
                                form.conc_cant_peajes == 0
                            "
                            @input="calcularTotal()"
                          />
                        </div>
                        <div class="form-group col-md-3">
                          <label>Cantidad Pernoctes</label
                          ><input
                            type="number"
                            class="form-control form-control-sm"
                            v-model="form.conc_cant_pernoctes"
                            :class="
                              $v.form.conc_cant_pernoctes.$invalid
                                ? 'is-invalid'
                                : 'is-valid'
                            "
                            :disabled="
                              modalData.tep_preliquidacion_nacional.estado ==
                                3 ||
                                !$store.getters.can(
                                  'tep.preliquidacionesNacionales.conciliarPreliquidacion'
                                )
                            "
                            @input="calcPernoctes()"
                          />
                          <span
                            >Valor Pernocte:
                            <strong>${{ form.costo_pernoctes }}</strong></span
                          >
                        </div>
                        <div class="form-group col-md-3">
                          <label>Costo Total</label>
                          <div class="input-group input-group-sm">
                            <div class="input-group-prepend">
                              <span class="input-group-text bg-navy">$</span>
                            </div>
                            <input
                              type="number"
                              class="form-control form-control-sm text-right"
                              v-model="form.costo_total"
                              readonly
                            />
                          </div>
                        </div>
                        <div class="form-group col-md-2 text-center mt-4">
                          <button
                            type="button"
                            class="btn btn-primary"
                            v-if="
                              !$v.form.$invalid &&
                                modalData.tep_preliquidacion_nacional.estado !=
                                  3 &&
                                $store.getters.can(
                                  'tep.preliquidacionesNacionales.conciliarPreliquidacion'
                                )
                            "
                            @click="save('conciliacion')"
                          >
                            Conciliar
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- Pestaña Firmas -->
                  <div
                    class="tab-pane fade"
                    id="Firmas"
                    v-if="
                      ($store.getters.can(
                        'tep.preliquidacionesNacionales.firmaFrontera'
                      ) ||
                        $store.getters.can(
                          'tep.preliquidacionesNacionales.firmaTransportadora'
                        )) &&
                        modalData.tep_preliquidacion_nacional &&
                        modalData.tep_preliquidacion_nacional.costo_total
                    "
                  >
                    <div class="panel-body p-3">
                      <div class="row">
                        <div class="form-group col-md-6">
                          <h5>Firma Frontera</h5>
                          <div
                            class="row"
                            v-if="
                              !modalData.tep_preliquidacion_nacional
                                .user_firma_frontera_id &&
                                $store.getters.can(
                                  'tep.preliquidacionesNacionales.firmaFrontera'
                                )
                            "
                          >
                            <div class="form-group col-md-12">
                              <div class="callout callout-warning">
                                <small
                                  ><strong>
                                    {{
                                      $store.getters.getAuthAzure
                                        ? `La sesión esta iniciada con Azure.`
                                        : `Para firmar la preliquidación por favor digite su clave.`
                                    }}
                                  </strong></small
                                >
                              </div>
                            </div>
                            <div
                              class="form-group col-md-6"
                              v-if="!$store.getters.getAuthAzure"
                            >
                              <label for="sign_password">Contraseña</label
                              ><input
                                type="password"
                                class="form-control form-control-sm"
                                v-model="firmas.fronteraForm.pass"
                                :class="
                                  $v.firmas.fronteraForm.pass.$invalid
                                    ? 'is-invalid'
                                    : 'is-valid'
                                "
                              />
                            </div>
                            <div
                              class="form-group col-md-6"
                              v-if="!$store.getters.getAuthAzure"
                            >
                              <label for="re_sign_password"
                                >Confirme Contraseña</label
                              ><input
                                type="password"
                                class="form-control form-control-sm"
                                v-model="firmas.fronteraForm.re_pass"
                                :class="
                                  $v.firmas.fronteraForm.re_pass.$invalid
                                    ? 'is-invalid'
                                    : 'is-valid'
                                "
                              />
                            </div>
                            <div class="form-group col-md-12">
                              <label for="observaciones">Observaciones</label>
                              <textarea
                                vtype="date"
                                rows="2"
                                class="form-control form-control-sm"
                                v-model="firmas.fronteraForm.obs_firma_frontera"
                                :class="
                                  $v.firmas.fronteraForm.obs_firma_frontera
                                    .$invalid
                                    ? 'is-invalid'
                                    : 'is-valid'
                                "
                              ></textarea>
                            </div>
                            <div class="form-group col-md-12">
                              <button
                                type="button"
                                title="Guardar firma"
                                data-toggle="tooltip"
                                class="btn btn-sm bg-success"
                                v-if="!$v.firmas.fronteraForm.$invalid"
                                @click="saveFirma()"
                              >
                                <i class="fas fa-signature"></i>
                                <div>
                                  <span
                                    ><small>Aprobar Preliquidación</small></span
                                  >
                                </div>
                              </button>
                            </div>
                          </div>
                          <div
                            class="row"
                            v-if="
                              !$store.getters.can(
                                'tep.preliquidacionesNacionales.firmaFrontera'
                              )
                            "
                          >
                            <div class="form-group col-md-12">
                              <div class="callout callout-warning">
                                <small
                                  ><strong
                                    >A la espera de aprobación de
                                    Frontera.</strong
                                  ></small
                                >
                              </div>
                            </div>
                          </div>
                          <div
                            class="row"
                            v-if="
                              modalData.tep_preliquidacion_nacional
                                .user_firma_frontera_id
                            "
                          >
                            <div class="form-group col-md-12">
                              <p>
                                Firmado por: <br />
                                <strong>{{
                                  modalData.tep_preliquidacion_nacional
                                    .usuario_frontera.name
                                }}</strong>
                              </p>
                              <p>
                                Fecha: <br />
                                <strong>{{
                                  modalData.tep_preliquidacion_nacional
                                    .fecha_firma_frontera
                                }}</strong>
                              </p>
                              <p>
                                Observación: <br />
                                {{
                                  modalData.tep_preliquidacion_nacional
                                    .obs_firma_frontera
                                }}
                              </p>
                            </div>
                          </div>
                        </div>
                        <div class="form-group col-md-6">
                          <h5>Firma Transportadora</h5>
                          <div
                            class="row"
                            v-if="
                              !modalData.tep_preliquidacion_nacional
                                .user_firma_trans_id &&
                                modalData.tep_preliquidacion_nacional
                                  .user_firma_frontera_id &&
                                $store.getters.can(
                                  'tep.preliquidacionesNacionales.firmaTransportadora'
                                )
                            "
                          >
                            <div class="form-group col-md-12">
                              <div class="callout callout-warning">
                                <small
                                  ><strong
                                    >Para firmar la preliquidación por favor
                                    digite su clave.</strong
                                  ></small
                                >
                              </div>
                            </div>
                            <div class="form-group col-md-6">
                              <label for="sign_password">Contraseña</label
                              ><input
                                type="password"
                                class="form-control form-control-sm"
                                v-model="firmas.transportadoraForm.pass"
                                :class="
                                  $v.firmas.transportadoraForm.pass.$invalid
                                    ? 'is-invalid'
                                    : 'is-valid'
                                "
                              />
                            </div>
                            <div class="form-group col-md-6">
                              <label for="re_sign_password"
                                >Confirme Contraseña</label
                              ><input
                                type="password"
                                class="form-control form-control-sm"
                                v-model="firmas.transportadoraForm.re_pass"
                                :class="
                                  $v.firmas.transportadoraForm.re_pass.$invalid
                                    ? 'is-invalid'
                                    : 'is-valid'
                                "
                              />
                            </div>
                            <div class="form-group col-md-12">
                              <label for="observaciones">Observaciones</label
                              ><textarea
                                vtype="date"
                                rows="2"
                                class="form-control form-control-sm"
                                v-model="
                                  firmas.transportadoraForm.obs_firma_trans
                                "
                                :class="
                                  $v.firmas.transportadoraForm.obs_firma_trans
                                    .$invalid
                                    ? 'is-invalid'
                                    : 'is-valid'
                                "
                              ></textarea>
                            </div>
                            <div class="form-group col-md-12">
                              <button
                                type="button"
                                title="Guardar firma"
                                data-toggle="tooltip"
                                class="btn btn-sm bg-success"
                                v-if="!$v.firmas.transportadoraForm.$invalid"
                                @click="saveFirma()"
                              >
                                <i class="fas fa-signature"></i>
                                <div>
                                  <span
                                    ><small>Aprobar Preliquidación</small></span
                                  >
                                </div>
                              </button>
                            </div>
                          </div>
                          <div
                            class="row"
                            v-if="
                              !modalData.tep_preliquidacion_nacional
                                .user_firma_frontera_id
                            "
                          >
                            <div class="form-group col-md-12">
                              <div class="callout callout-warning">
                                <small
                                  ><strong
                                    >A la espera de aprobación de
                                    Frontera.</strong
                                  ></small
                                >
                              </div>
                            </div>
                          </div>
                          <div
                            class="row"
                            v-if="
                              !$store.getters.can(
                                'tep.preliquidacionesNacionales.firmaTransportadora'
                              )
                            "
                          >
                            <div class="form-group col-md-12">
                              <div class="callout callout-warning">
                                <small
                                  ><strong
                                    >A la espera de aprobación de la
                                    Transportadora.</strong
                                  ></small
                                >
                              </div>
                            </div>
                          </div>
                          <div
                            class="row"
                            v-if="
                              modalData.tep_preliquidacion_nacional
                                .user_firma_trans_id
                            "
                          >
                            <div class="form-group col-md-12">
                              <p>
                                Firmado por: <br />
                                <strong>{{
                                  modalData.tep_preliquidacion_nacional
                                    .usuario_transportadora.name
                                }}</strong>
                              </p>
                              <p>
                                Fecha: <br />
                                <strong>{{
                                  modalData.tep_preliquidacion_nacional
                                    .fecha_firma_trans
                                }}</strong>
                              </p>
                              <p>
                                Observación: <br />
                                {{
                                  modalData.tep_preliquidacion_nacional
                                    .obs_firma_trans
                                }}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="modal-footer justify-content-between">
                <button
                  type="button"
                  class="btn btn-secondary"
                  data-dismiss="modal"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <TepPreliquidacionNacionalExport ref="TepPreliquidacionNacionalExport" />
  </div>
</template>

<script>
import Loading from "../../../../components/Loading";
import pagination from "laravel-vue-pagination";
import vSelect from "vue-select";
import axios from "axios";
import { required, sameAs } from "vuelidate/lib/validators";
import TepPreliquidacionNacionalExport from "./TepPreliquidacionNacionalExport";

export default {
  name: "TepPreliquidacionNacionalIndex",
  components: {
    Loading,
    pagination,
    vSelect,
    TepPreliquidacionNacionalExport,
  },
  data() {
    return {
      formContrato: {
        id: null,
      },
      contrato: [],
      filterTarifas: "",
      actualPage: null,
      cargando: false,
      preliquidaciones: {},
      preliquidacion_opt: {},
      transportadora: null,
      tipo_vehiculo: null,
      vehiculo: null,
      conductor: null,
      ruta: null,
      fecha_ini: null,
      fecha_fin: null,
      round_trip: false,
      bloq: 0,
      // datos para el mapa
      preliquidacion_id: null,
      traza_viaje: null,
      center: {},
      zoom: 10,
      markers: [],
      markers_viaje: [],
      infoContent: "",
      infoWindowPos: {
        lat: 0,
        lng: 0,
      },
      mensaje_error: null,
      infoWinOpen: false,
      currentMidx: null,
      infoOptions: {
        pixelOffset: {
          width: 0,
          height: -35,
        },
      },
      locations: {},
      poligonos: [],
      circunferencias: [],
      filtros: {
        fecha_ini: null,
        fecha_fin: null,
      },
      modalTitle: null,
      modalData: null,
      detTarifa: null,
      form: {
        id: null,
        horas_trabajadas: null,
        km_recorridos: null,
        conc_horas_trabajadas: null,
        conc_km_recorridos: null,
        cant_km_add: null,
        cant_horas_add: null,
        conc_cant_pernoctes: null,
        cant_peajes: null,
        cant_peajes_pago: null,
        conc_cant_km_add: null,
        conc_cant_horas_add: null,
        conc_cant_peajes: null,
        costo_tarifa: null,
        costo_km_add: null,
        costo_horas_add: null,
        costo_pernoctes: null,
        costo_peajes: null,
        costo_total: null,
      },
      firmas: {
        fronteraForm: {
          id: null,
          obs_firma_frontera: null,
          pass: null,
          re_pass: null,
        },
        transportadoraForm: {
          id: null,
          obs_firma_trans: null,
          pass: null,
          re_pass: null,
        },
      },
      preLiqForm: {},
      listasForms: {
        transportadoras: [],
        tipos_vehiculos: [],
        vehiculos: [],
        conductores: [],
        rutas: [],
        estados: [],
        contratos: [],
      },
      uri_docs: process.env.VUE_APP_AWS_BUCKET_URL,
    };
  },
  validations() {
    let firm_front = {};
    if (!this.$store.getters.getAuthAzure) {
      firm_front = {
        id: {
          required,
        },
        pass: {
          required,
        },
        re_pass: {
          required,
          sameAsPassword: sameAs("pass"),
        },
        obs_firma_frontera: {
          required,
        },
      };
    } else {
      firm_front = {
        id: {
          required,
        },
        obs_firma_frontera: {
          required,
        },
      };
    }

    return {
      form: {
        id: {
          required,
        },
        horas_trabajadas: {
          required,
        },
        km_recorridos: {
          required,
        },
        conc_horas_trabajadas: {
          required,
        },
        conc_km_recorridos: {
          required,
        },
        cant_km_add: {
          required,
        },
        cant_horas_add: {
          required,
        },
        conc_cant_pernoctes: {
          required,
        },
        cant_peajes: {
          required,
        },
        cant_peajes_pago: {
          required,
        },
        conc_cant_km_add: {
          required,
        },
        conc_cant_horas_add: {
          required,
        },
        conc_cant_peajes: {
          required,
        },
        costo_tarifa: {
          required,
        },
        costo_km_add: {
          required,
        },
        costo_horas_add: {
          required,
        },
        costo_pernoctes: {
          required,
        },
        costo_peajes: {
          required,
        },
        costo_total: {
          required,
        },
      },
      firmas: {
        fronteraForm: firm_front,
        transportadoraForm: {
          id: {
            required,
          },
          pass: {
            required,
          },
          re_pass: {
            required,
            sameAsPassword: sameAs("pass"),
          },
          obs_firma_trans: {
            required,
          },
        },
      },
      preLiqForm: {
        required,
      },
    };
  },

  computed: {
    filteredRowsDetail() {
      if (this.preliquidacion_opt.preliquidacion) {
        return this.preliquidacion_opt.preliquidacion.filter((det) => {
          const tarifa = det.view.tarifa.toLowerCase();
          const searchTermtarifa = this.filterTarifas.toLowerCase();
          return tarifa.includes(searchTermtarifa);
        });
      } else {
        return this.preliquidacion_opt.preliquidacion;
      }
    },
  },

  methods: {
    getIndex(page = 1) {
      this.cargando = true;
      axios
        .get("/api/tep/preliquidacionesNacionales?page=" + page, {
          params: this.filtros,
        })
        .then((response) => {
          this.cargando = false;
          this.preliquidaciones = response.data;
        });
    },

    /***** Funciones para los campos del filtro */
    getTransportadora() {
      let me = this;
      axios
        .get("api/admin/empresas/listaTep?estado=1")
        .then(function(response) {
          me.listasForms.transportadoras = response.data;
        });
    },

    async getEstados() {
      await axios.get("/api/lista/98").then((response) => {
        this.listasForms.estados = response.data;
        
      });
    },

    seleccionarTransportadora() {
      if (this.transportadora != null) {
        this.filtros.empresa_id = this.transportadora.id;
      } else {
        this.filtros.empresa_id = null;
      }
    },

    getTiposVehiculos(tipo_vehiculos) {
      let me = this;
      var url = "api/admin/tiposVehiculos/lista";
      axios
        .get(url, {
          params: {
            linea_negocio_id: 5,
          },
        })
        .then(function(response) {
          let respuesta = response.data;
          me.listasForms.tipos_vehiculos = respuesta;
        })
        .catch(function(error) {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    seleccionarTipoVehiculo() {
      if (this.tipo_vehiculo != null) {
        this.filtros.tipo_vehiculo_id = this.tipo_vehiculo.id;
      } else {
        this.filtros.tipo_vehiculo_id = null;
      }
    },

    getVehiculos() {
      let me = this;
      axios.get("/api/admin/vehiculos/lista").then((response) => {
        me.listasForms.vehiculos = response.data;
      });
    },

    seleccionarVehiculo() {
      if (this.vehiculo != null) {
        this.filtros.vehiculo_id = this.vehiculo.id;
      } else {
        this.filtros.vehiculo_id = null;
      }
    },

    getConductores() {
      let me = this;
      axios.get("/api/admin/conductores/lista").then((response) => {
        me.listasForms.conductores = response.data;
      });
    },

    seleccionarConductor() {
      if (this.conductor != null) {
        this.filtros.conductor_id = this.conductor.id;
      } else {
        this.filtros.conductor_id = null;
      }
    },

    getRuta() {
      let me = this;
      axios.get("/api/tep/Ruta/lista").then((response) => {
        me.listasForms.rutas = response.data;
      });
    },

    seleccionarRuta() {
      if (this.ruta != null) {
        this.filtros.ruta = this.ruta.id;
      } else {
        this.filtros.ruta = null;
      }
    },

    getContrato() {
      let me = this;
      axios
        .get("/api/tep/contratosNacionales/lista", {
          params: {
            empresa_id: me.modalData.transportadora_id,
          },
        })
        .then((response) => {
          me.listasForms.contratos = response.data;
        });
    },

    seleccionarContrato() {
      this.formContrato.id = null;
      if (this.contrato) {
        this.formContrato.id = this.contrato.id;
        this.calcularPreLiquidacion(this.modalData);
      }
    },

    limpiar() {
      this.filtros = {
        fecha_ini: null,
        fecha_fin: null,
      };
      this.transportadora = null;
      this.tipo_vehiculo = null;
      this.vehiculo = null;
      this.conductor = null;
      this.tipo_operacion = null;
      this.operacion = null;
      this.fecha_ini = null;
      this.fecha_fin = null;
      this.getIndex();
    },
    /***** Fin - Funciones para los campos del filtro */

    /***** Funciones para cargar los datos del modal */
    show(id) {
      axios
        .get("/api/tep/preliquidacionesNacionales?id=" + id)
        .then((response) => {
          this.cargando = false;
          this.getModalData(response.data.data[0]);
        });
    },

    async getModalData(preliquidacion) {
      this.modalTitle = `Detalle Preliquidación`;
      this.modalData = null;
      await this.limpiarContrato();
      this.modalData = preliquidacion;
      if (
        this.$store.getters.can(
          "tep.preliquidacionesNacionales.calcularPreLiquidacionNacional"
        )
      ) {
        await this.contratoVisualizacion();
      }
      await this.getContrato();
      await this.getFormConciliacion(preliquidacion);
      await this.resetFormFirmas(preliquidacion.tep_preliquidacion_nacional);
    },

    resetFormFirmas(tep_preliquidacion_nacional) {
      if (this.modalData.tep_preliquidacion_nacional) {
        this.firmas = {
          fronteraForm: {
            id: tep_preliquidacion_nacional.id,
            obs_firma_frontera: null,
            pass: null,
            re_pass: null,
          },
          transportadoraForm: {
            id: tep_preliquidacion_nacional.id,
            obs_firma_trans: null,
            pass: null,
            re_pass: null,
          },
        };
      }
    },

    getFormConciliacion(preliquidacion) {
      const data = preliquidacion.tep_preliquidacion_nacional;
      if (data) {
        this.detTarifa = data.det_tarifa_nacional;

        let conc_horas_trabajadas = data.conc_horas_trabajadas;
        if (!data.conc_horas_trabajadas) {
          conc_horas_trabajadas = data.horas_trabajadas;
        }

        let conc_km_recorridos = data.conc_km_recorridos;
        if (!data.conc_km_recorridos) {
          conc_km_recorridos = data.km_recorridos;
        }

        let conc_cant_km_add = data.conc_cant_km_add;
        if (!data.conc_cant_km_add) {
          conc_cant_km_add = data.cant_km_add;
        }

        let conc_cant_horas_add = data.conc_cant_horas_add;
        if (!data.conc_cant_horas_add) {
          conc_cant_horas_add = data.cant_horas_add;
        }

        let conc_cant_peajes = data.conc_cant_peajes;
        if (!data.conc_cant_peajes) {
          conc_cant_peajes = data.cant_peajes_pago;
        }

        let conc_cant_pernoctes = data.conc_cant_pernoctes;
        if (!data.conc_cant_pernoctes) {
          conc_cant_pernoctes = 0;
        }

        this.form = {
          id: data.id,
          horas_trabajadas: data.horas_trabajadas,
          km_recorridos: data.km_recorridos,
          conc_horas_trabajadas,
          conc_km_recorridos,
          cant_km_add: data.cant_km_add,
          cant_horas_add: data.cant_horas_add,
          conc_cant_pernoctes,
          cant_peajes: data.cant_peajes,
          cant_peajes_pago: data.cant_peajes_pago,
          conc_cant_km_add,
          conc_cant_horas_add,
          conc_cant_peajes,
          costo_tarifa: data.costo_tarifa,
          costo_km_add: data.costo_km_add,
          costo_horas_add: data.costo_horas_add,
          costo_pernoctes: data.costo_pernoctes,
          costo_peajes: data.costo_peajes,
          costo_total: data.costo_total,
        };
        this.calcularTotal();
      }
    },
    /***** Fin - Funciones para cargar los datos del modal */

    /***** Funciones de validaciones de los formularios */
    validaFechasFiltro() {
      if (this.filtros.fecha_ini && this.filtros.fecha_fin) {
        this.validaFechas(this.filtros.fecha_ini, this.filtros.fecha_fin);
      }
    },

    validaFechas(fecha_menor, fecha_mayor) {
      fecha_menor = new Date(fecha_menor);
      fecha_mayor = new Date(fecha_mayor);
      // Se valida que la fecha inicial sea menor que la fecha final
      if (fecha_menor > fecha_mayor) {
        this.filtros.fecha_fin = null;
        this.$swal({
          icon: "error",
          title: `La fecha inicial no puede ser mayor a la fecha final...`,
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
      }
    },
    /***** Fin - Funciones de validaciones de los formularios */

    /***** Funciones para calcular preliquidaciones */
    async calcularPreLiquidacion(preliquidacion) {
      this.cargando = true;
      this.preliquidacion_opt = {};
      this.preLiqForm = {};
      const params = {
        tep_viaje_id: preliquidacion.id,
        tep_ruta_id: preliquidacion.tep_ruta_id,
        tipo_vehiculo_id: preliquidacion.tipo_vehiculo_id,
        transportadora_id: preliquidacion.transportadora_id,
        fecha_ini: preliquidacion.fecha_ini,
        fecha_fin: preliquidacion.fecha_fin,
        odometro_ini: preliquidacion.odometro_ini,
        odometro_fin: preliquidacion.odometro_fin,
        peajes_solicitud: preliquidacion.peajes.length,
        round_trip: this.round_trip ? 1 : null,
        contrato_id: this.formContrato.id,
      };
      await axios
        .put(
          "/api/tep/preliquidacionesNacionales/calcularPreLiquidacionNacional",
          params
        )
        .then((response) => {
          this.cargando = false;
          if (!response.data.error) {
            this.preliquidacion_opt = response.data;
          } else {
            this.$swal({
              icon: "error",
              title: response.data.error,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          }
        })
        .catch((e) => {
          this.cargando = false;
          this.$swal({
            icon: "error",
            title: "Ocurrió un error - " + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    getPreliquidacionForm(form) {
      this.preLiqForm = form;
      // Se filtra si ya existe una preliquidación y se asigna el id
      if (this.modalData.tep_preliquidacion_nacional) {
        this.preLiqForm.id = this.modalData.tep_preliquidacion_nacional.id;
      }
    },
    /***** Fin - Funciones para calcular preliquidaciones */

    /**** Funciones para cargar los datos de la trazabilidad */
    getTraza() {
      if (this.preliquidacion_id != this.modalData.id) {
        this.cargando = true;
        this.preliquidacion_id = this.modalData.id;
        const params = {
          vehiculo_id: this.modalData.vehiculo_id,
          fecha_inicial: this.modalData.fecha_ini,
          fecha_final: this.modalData.fecha_fin,
        };

        axios
          .get("/api/tep/preliquidacionesNacionales/trazabilidad", {
            params,
          })
          .then((response) => {
            if (!response.data.msg_error) {
              this.traza_viaje = response.data.traza;
              const datos_mapa = response.data.datos_mapa;
              this.center = {
                lat: parseFloat(datos_mapa.centro_mapa.latitud),
                lng: parseFloat(datos_mapa.centro_mapa.longitud),
              };
              this.zoom = datos_mapa.zoom;
              this.mensaje_error = null;
              this.getMapa();
            } else {
              this.$swal({
                icon: "error",
                title: response.data.msg_error,
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 4000,
                timerProgressBar: true,
              });
              this.center = {
                lat: parseFloat(this.modalData.lat_ini_vehiculo),
                lng: parseFloat(this.modalData.lon_ini_vehiculo),
              };
              this.mensaje_error = response.data.msg_error;
              this.traza_viaje = null;
              this.zoom = 16;
              this.cargando = false;
            }
          });
      }
    },

    async showGeocercas() {
      this.cargando = true;
      await axios
        .get("/api/tep/mapas/showGeocercasZona")
        .then((response) => {
          this.cargando = false;
          this.locations = response.data.gps_fin;
          this.locations.geocerca_poli.forEach((geocerca) => {
            if (geocerca.puntos.length > 0) {
              let puntos = [];
              geocerca.puntos.forEach((punto) => {
                puntos.push({
                  lng: parseFloat(punto.longitud),
                  lat: parseFloat(punto.latitud),
                });
              });

              this.poligonos.push({
                id: geocerca.id,
                name: geocerca.desc,
                path: puntos,
                info: geocerca.ventana,
                // color: geocerca.color,
              });
            }
          });
          for (var j = 0; j < this.locations.geocerca_cir.length; j++) {
            if (this.locations.geocerca_cir[j]["lat"] !== "") {
              this.circunferencias.push({
                center: {
                  lat: parseFloat(this.locations.geocerca_cir[j]["lat"]),
                  lng: parseFloat(this.locations.geocerca_cir[j]["lon"]),
                },
                radius: parseFloat(this.locations.geocerca_cir[j]["radio"]),
                info: this.locations.geocerca_cir[j]["ventana"],
              });
            }
          }
        })
        .catch(function(error) {
          this.cargando = false;
        });
    },

    async getMapa() {
      let conductor_inicio = null;
      let conductor_destino = null;
      let user_inicio = null;
      let user_destino = null;
      let info_llegada = null;

      if (this.modalData.lat_long_ini_conductor != null) {
        let conductor_ini = this.modalData.lat_long_ini_conductor.split(",");

        conductor_inicio = {
          lat: parseFloat(conductor_ini[0]),
          lng: parseFloat(conductor_ini[1]),
        };
      }
      if (this.modalData.lat_long_fin_conductor != null) {
        let conductor_fin = this.modalData.lat_long_fin_conductor.split(",");

        conductor_destino = {
          lat: parseFloat(conductor_fin[0]),
          lng: parseFloat(conductor_fin[1]),
        };
      }
      if (this.modalData.lat_long_ini_user != null) {
        let user_ini = this.modalData.lat_long_ini_user.split(",");

        user_inicio = {
          lat: parseFloat(user_ini[0]),
          lng: parseFloat(user_ini[1]),
        };
      }
      if (this.modalData.lat_long_fin_user != null) {
        let user_fin = this.modalData.lat_long_fin_user.split(",");

        user_destino = {
          lat: parseFloat(user_fin[0]),
          lng: parseFloat(user_fin[1]),
        };
      }
      if (this.modalData.lat_long_llegada != null) {
        let llegada = this.modalData.lat_long_llegada.split(",");

        info_llegada = {
          lat: parseFloat(llegada[0]),
          lng: parseFloat(llegada[1]),
        };
      }

      await this.addMarkers(
        conductor_inicio,
        conductor_destino,
        user_inicio,
        user_destino,
        info_llegada
      );
    },

    async addMarkers(
      conductor_inicio,
      conductor_destino,
      user_inicio,
      user_destino,
      info_llegada
    ) {
      await this.limpiarMapa();
      await this.traza_viaje.forEach((traza) => {
        const punto = {
          lat: parseFloat(traza.dblLatitud),
          lng: parseFloat(traza.dblLongitud),
        };
        const info = `
          <div class="wrapper">
            <h3 style="font-size: 15px" class="text-center">Vehículo: ${traza.strPlaca}</h3>
            <hr />
            <b>Fecha reporte:</b> ${traza.dteFechaReporte}<br />
            <b>Posicion:</b> ${traza.dblLatitud}, ${traza.dblLongitud} <br />
            <b>Velocidad:</b> ${traza.intVelocidadInstantanea} Km/h<br />
            <b>Transportadora:</b> ${traza.strDocTransportadora}<br />
            <b>Distancia Recorrida:</b> ${traza.intDistanciaRecorrida}
          </div>
        `;
        this.markers_viaje.push({
          id: traza.id,
          position: punto,
          info,
          icon: "/img/icon_viaje.png",
        });
      });

      // Datos del Origen Conductor
      const info_origen_con = `
        <div class="wrapper">
          <h3 style="font-size: 15px" class="text-center">Inicio Conductor</h3>
        </div>
      `;
      await this.markers.push({
        position: conductor_inicio,
        info: info_origen_con,
        icon: "/img/volante1.png",
      });

      // Datos del Destino Conductor
      const info_destino_con = `
        <div class="wrapper">
          <h3 style="font-size: 15px" class="text-center">Fin Conductor</h3>
        </div>
      `;
      await this.markers.push({
        position: conductor_destino,
        info: info_destino_con,
        icon: "/img/volante2.png",
      });

      // Datos del Origen User
      const info_origen_user = `
        <div class="wrapper">
          <h3 style="font-size: 15px" class="text-center">Inicio Usuario</h3>
        </div>
      `;
      await this.markers.push({
        position: user_inicio,
        info: info_origen_user,
        icon: "/img/user1.png",
      });

      // Datos del Destino User
      const info_destino_user = `
        <div class="wrapper">
          <h3 style="font-size: 15px" class="text-center">Fin Usuario</h3>
        </div>
      `;
      await this.markers.push({
        position: user_destino,
        info: info_destino_user,
        icon: "/img/user.png",
      });

      // Datos LLegada
      const llegada = `
        <div class="wrapper">
          <h3 style="font-size: 15px" class="text-center">Llegada</h3>
        </div>
      `;
      await this.markers.push({
        position: info_llegada,
        info: llegada,
        icon: "/img/icon_punto_g.png",
      });

      this.cargando = false;
    },

    toggleInfoWindow: function(marker, idx, ventana) {
      this.infoWindowPos = marker.position;
      this.infoContent = ventana;
      this.center = marker.position;

      //comprueba si es el mismo marcador que se seleccionó y en caso afirmativo alternar
      if (this.currentMidx == idx) {
        this.infoWinOpen = !this.infoWinOpen;
      }
      //si un marcador diferente establece la ventana de información para abrir y restablecer el índice de marcador actual
      else {
        this.infoWinOpen = true;
        this.currentMidx = idx;
      }
    },
    toggleInfoWindowPoli: function(poli, idx, ventana) {
      this.infoWindowPos = poli.path[0];
      this.infoContent = ventana;
      this.center = poli.path[0];

      //compruebe si es el mismo marcador que se seleccionó y en caso afirmativo alternar
      if (this.currentMidx == idx) {
        this.infoWinOpen = !this.infoWinOpen;
      }
      //si un marcador diferente establece la ventana de información para abrir y restablecer el índice de marcador actual
      else {
        this.infoWinOpen = true;
        this.currentMidx = idx;
      }
    },
    toggleInfoWindowCir: function(cir, idx, ventana) {
      this.infoWindowPos = cir.center;
      this.infoContent = ventana;
      this.center = cir.center;

      //compruebe si es el mismo marcador que se seleccionó y en caso afirmativo alternar
      if (this.currentMidx == idx) {
        this.infoWinOpen = !this.infoWinOpen;
      }
      //si un marcador diferente establece la ventana de información para abrir y restablecer el índice de marcador actual
      else {
        this.infoWinOpen = true;
        this.currentMidx = idx;
      }
    },

    limpiarMapa() {
      this.markers = [];
      this.markers_viaje = [];
    },
    /**** Fin - Funciones para cargar los datos de la trazabilidad */

    /***** Funciones para el formulario de conciliaciones */
    calcCostoHorasExtra() {
      this.form.costo_horas_add =
        this.detTarifa.valor_horas_extra * this.form.conc_cant_horas_add;
      this.calcularTotal();
    },

    calcCostoKmAdicionales() {
      this.form.costo_km_add =
        this.detTarifa.valor_km_adicional * this.form.conc_cant_km_add;
      this.calcularTotal();
    },

    calcPernoctes() {
      this.form.costo_pernoctes =
        this.detTarifa.tarifa_nacional.contrato.pernocte *
        this.form.conc_cant_pernoctes;
      this.calcularTotal();
    },

    calcPeajes() {
      if (!this.form.conc_cant_peajes || this.form.conc_cant_peajes == 0) {
        this.form.costo_peajes = 0;
      }
      this.calcularTotal();
    },

    calcularTotal() {
      this.form.costo_total =
        parseFloat(this.form.costo_tarifa) +
        parseFloat(this.form.costo_km_add) +
        parseFloat(this.form.costo_horas_add) +
        parseFloat(this.form.costo_pernoctes) +
        parseFloat(this.form.costo_peajes);
    },
    /***** Fin - Funciones para el formulario de conciliaciones */

    /***** Funciones para firmar */
    saveFirma() {
      this.cargando = true;
      let me = this;
      if (
        !this.$v.firmas.transportadoraForm.$invalid ||
        !this.$v.firmas.fronteraForm.$invalid
      ) {
        let pass = null;
        let url = null;
        let data = {};

        // Se valida si la firma es de Frontera
        if (!this.$v.firmas.fronteraForm.$invalid) {
          url = "/api/tep/preliquidacionesNacionales/saveFirmaFrontera";
          pass = this.firmas.fronteraForm.pass;
          data = {
            id: this.firmas.fronteraForm.id,
            obs_firma_frontera: this.firmas.fronteraForm.obs_firma_frontera,
            user_firma_frontera_id: this.$store.getters.getUser.id,
            loginAzure: this.$store.getters.getAuthAzure,
          };
          if (this.fileDoc) {
            let formData = new FormData();
            formData.append("firma_id", this.firmas.fronteraForm.id);
            formData.append(
              "Observaciones",
              this.firmas.fronteraForm.obs_firma_frontera
            );
            formData.append("file", this.fileDoc);

            axios
              .post(
                "/api/tep/preliquidacionesNacionales/documentos",
                formData,
                {
                  headers: {
                    "Content-Type": "multipart/form-data",
                  },
                }
              )
              .then((res) => {
                if (res.data.msg) {
                  this.$swal({
                    icon: "succes",
                    title: res.data.msg,
                    toast: true,
                    position: "top-end",
                    showConfirmButton: false,
                    timer: 4000,
                    timerProgressBar: true,
                  });
                }
                this.cargando = false;
              })
              .catch((e) => {
                this.$swal({
                  icon: "error",
                  title: "Ocurrio un error, vuelva a intentarlo..." + e,
                  toast: true,
                  position: "top-end",
                  showConfirmButton: false,
                  timer: 4000,
                  timerProgressBar: true,
                });
                this.cargando = false;
              });
          }
        }

        // Se valida si la firma es de la transportadora
        if (!this.$v.firmas.transportadoraForm.$invalid) {
          url = "/api/tep/preliquidacionesNacionales/saveFirmaTransportadora";
          pass = this.firmas.transportadoraForm.pass;
          data = {
            id: this.firmas.transportadoraForm.id,
            obs_firma_trans: this.firmas.transportadoraForm.obs_firma_trans,
            user_firma_trans_id: this.$store.getters.getUser.id,
          };
        }
        let form = { data, pass };

        axios({
          method: "PUT",
          url,
          data: form,
        })
          .then((response) => {
            if (!response.data.user_error) {
              this.cargando = false;
              me.getIndex();
              this.$refs.closeModal.click();

              me.$swal({
                icon: "success",
                title: "La preliquidación fue autorizada exitosamente...",
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
            } else {
              this.cargando = false;
              me.$swal({
                icon: "error",
                title: response.data.user_error,
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
            }
          })
          .catch((e) => {
            this.cargando = false;
            me.$swal({
              icon: "error",
              title: "Ocurrió un error: " + e,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },

    save(element) {
      switch (element) {
        case "ininerario":
          this.saveItinerario();
          break;

        case "conciliacion":
          this.saveConciliacion();
          break;

        default:
          break;
      }
    },

    // Función para guardar las preliquidaciones
    async saveItinerario() {
      if (!this.$v.preLiqForm.$invalid) {
        this.cargando = true;
        await axios({
          method: "PUT",
          url: "/api/tep/preliquidacionesNacionales/savePreliqidacion",
          data: this.preLiqForm,
        })
          .then((response) => {
            this.cargando = false;
            this.actualPage = this.preliquidaciones.current_page;
            this.modalData.tep_preliquidacion_nacional = response.data[0];
            this.preLiqForm = {};
            this.getIndex(this.actualPage);
            this.show(this.modalData.id);
            this.$swal({
              icon: "success",
              title: "La guardó exitosamente la preliquidación...",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          })
          .catch((e) => {
            this.cargando = false;
            this.$swal({
              icon: "error",
              title: "Ocurrio un error, vuelva a intentarlo..." + e,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },

    verPDF(id_solicitud) {
      this.cargando = true;
      axios({
        method: "get",
        url: "/api/tep/viajes/pdf/" + id_solicitud,
      })
        .then((response) => {
          let data = response.data;
          this.cargando = false;
          window.open(data.url, "_blank");
        })
        .catch((e) => {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
          this.cargando = false;
        });
    },

    // Función para guardar las conciliaciones
    async saveConciliacion() {
      if (!this.$v.form.$invalid) {
        this.cargando = true;
        await axios({
          method: "PUT",
          url: "/api/tep/preliquidacionesNacionales/saveConciliacion",
          data: this.form,
        })
          .then((response) => {
            this.cargando = false;
            this.actualPage = this.preliquidaciones.current_page;
            this.modalData.tep_preliquidacion_nacional = response.data[0];
            this.getIndex(this.actualPage);
            this.show(this.modalData.id);
            this.$swal({
              icon: "success",
              title: "La guardó exitosamente la conciliación...",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          })
          .catch((e) => {
            this.cargando = false;
            this.$swal({
              icon: "error",
              title: "Ocurrio un error, vuelva a intentarlo..." + e,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },

    limpiarContrato() {
      this.formContrato = {
        id: null,
      };
      this.contrato = [];
    },

    contratoVisualizacion() {
      this.bloq = 0;
      /* Variable Contrato */
      let contrato = null;
      /* Se obtiene el contrato */

      if (this.modalData.tep_preliquidacion_nacional) {
        this.calcularPreLiquidacion(this.modalData);
        this.bloq = 1;
        contrato = this.modalData.tep_preliquidacion_nacional
          .det_tarifa_nacional.tarifa_nacional.contrato;

        /* Se obtiene asigna el contrato a las variables necesarias*/

        this.formContrato.id = contrato.id;
        this.contrato = {
          descripcion: contrato.no_contrato + " " + contrato.observaciones,
          empresa_id: contrato.empresa_id,
          id: contrato.id,
          no_contrato: contrato.no_contrato,
          nombre: contrato.nombre,
        };
      }
    },
  },
  mounted() {
    this.getIndex();
    this.getTransportadora();
    this.getTiposVehiculos();
    this.getVehiculos();
    this.getConductores();
    this.getRuta();
    this.getEstados();
  },
};
</script>
